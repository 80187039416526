import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/index.css";
import "./assets/css/iconfont.css";
import config from "./assets/js/config";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import moment from "moment";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { generaMenu } from "./assets/js/menu";
import {parseTime, resetForm, addDateRange, formatMoney, formatYuan, handleTree, selectDictLabel} from "@/utils/ruoyi";
import VueCookie from "vue-cookie";
import plugins from "./plugins"; // plugins
import { download } from '@/utils/request'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 字典数据组件
import DictData from '@/components/DictData'
import {getDicts} from "@/api/dict/data";
import { getToken } from "@/utils/auth";

Vue.prototype.config = config;
Vue.prototype.getDicts = getDicts
Vue.prototype.selectDictLabel = selectDictLabel
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.parseTime = parseTime
Vue.prototype.formatMoney = formatMoney
Vue.prototype.formatYuan = formatYuan
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.handleTree = handleTree
Vue.prototype.download = download
//全局组件
Vue.component('DictTag', DictTag)

Vue.use(VueCookie)   // 挂载在全局了
Vue.use(mavonEditor);
Vue.use(ElementUI);
Vue.use(plugins)


DictData.install()

Vue.filter("date", function(value, formatStr = "YYYY-MM-DD") {
  return moment(value).format(formatStr);
});

Vue.filter("dateTime", function(value, formatStr = "YYYY-MM-DD HH:mm:ss") {
  return moment(value).format(formatStr);
});

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path === "/login") {
    next();
  } else if (!getToken()) {
    next({ path: "/login" });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 刷新页面查询用户菜单
    if (getToken()) {
      generaMenu();
    }
  }
}).$mount("#app");
