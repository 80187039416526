import { getToken, setToken, removeToken } from "@/utils/auth";
import { adminLogin,adminLogout } from "@/api/login";
import {resetRouter} from "@/router";
import {generaMenu} from "@/assets/js/menu";
import {updateUserInfo} from "@/api/user";
import {Message} from "element-ui";
const user = {
  state: {
    userId: '',
    token: getToken(),
    roleList: [],
    avatar: '',
    nickname: '',
    intro: '',
    webSite: '',
    userMenuList: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_ROLE_LIST: (state, roleList) => {
      state.roleList = roleList;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_INTRO: (state, intro) => {
      state.intro = intro;
    },
    SET_WEBSITE: (state, webSite) => {
      state.webSite = webSite;
    },
    SET_USER_MENU_LIST: (state, userMenuList) => {
      state.userMenuList = userMenuList;
    },
    SET_ADMIN_LOGOUT: (state) =>{
      state.token = ''
      state.userId = ''
      state.roleList = []
      state.avatar = ''
      state.nickname = ''
      state.webSite = ''
      state.intro = ''
      removeToken();
      // 清空用户菜单
      resetRouter();
    }
  },
  actions: {
    Login({ commit }, loginForm) {
      const username = loginForm.username.trim()
      const password = loginForm.password
      const code = loginForm.code
      const uuid = loginForm.uuid
      return new Promise((resolve, reject) => {
        adminLogin(username, password, code, uuid).then(response => {
          const user = response.data;
          setToken(user.token);
          commit("SET_TOKEN", user.token);
          commit("SET_USERID", user.userInfoId);
          commit("SET_ROLE_LIST", user.roleList);
          commit("SET_AVATAR", user.avatar);
          commit("SET_NICKNAME", user.nickname);
          commit("SET_INTRO", user.intro);
          commit("SET_WEBSITE", user.webSite);
          // 加载用户菜单
          generaMenu();
          resolve()
        }).catch(error => {
          reject(error)
        });
      })
    },
    // 退出系统
    AdminLogout({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        adminLogout().then(() => {
          commit('SET_ADMIN_LOGOUT')
          dispatch("tab/resetTab")
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    UpdateAvatar({ commit }, avatar) {
      commit("SET_AVATAR", avatar);
    },
    UpdateAdminInfo({ commit }, user) {
      return new Promise((resolve, reject) => {
        updateUserInfo(user).then(response => {
          if (response.flag) {
            Message.success(response.message);
            commit("SET_NICKNAME", user.nickname);
            commit("SET_INTRO", user.intro);
            commit("SET_WEBSITE", user.webSite);
          } else {
            Message.error(response.message);
          }
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
};

export default user;
