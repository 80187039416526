import request from "@/utils/request";

// 登录方法
export function adminLogin(username, password, code, uuid) {
  const data = {
    username: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return request({
    url: "/login",
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: "post",
    data: data
  });
}

// 退出方法
export function adminLogout() {
  return request({
    url: "/logout",
    method: "post"
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/captchaImage",
    headers: {
      isToken: false
    },
    method: "get",
    timeout: 20000
  });
}
