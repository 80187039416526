import Layout from "@/layout/index.vue";
import router from "@/router";
import store from "@/store";
import Vue from "vue";
import { getUserMenusList } from "@/api/user";

export function generaMenu() {
  // 查询用户菜单
  getUserMenusList().then(res => {
    if (res.flag) {
      var userMenuList = res.data;
      userMenuList.forEach(item => {
        if (item.component === "Layout") {
          item.component = Layout;
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach(route => {
            route.component = loadView(route.component);
          });
        }
      });
      // 添加侧边栏菜单
      store.commit("SET_USER_MENU_LIST", userMenuList);
      // 添加菜单到路由
      router.addRoutes(userMenuList);
    } else {
      Vue.prototype.$message.error(res.message);
      router.push({ path: "/login" });
    }
  });
}

export const loadView = view => {
  // 路由懒加载
  return resolve => require([`@/views${view}`], resolve);
};
