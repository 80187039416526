const getters = {
  userId: state => state.user.userId,
  collapse: state => state.app.collapse,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  intro: state => state.user.intro,
  roleList: state => state.user.roleList,
  tabList: state => state.tab.tabList,
  webSite: state => state.user.webSite,
  userMenuList: state => state.user.userMenuList,
  dict: state => state.dict.dict,
}
export default getters
