const state = {
  collapse: false,
}
const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.collapse = !state.collapse;
  },
}
const actions = {
  toggleSideBar({ commit, state}, ) {
    commit('TOGGLE_SIDEBAR',state);
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
