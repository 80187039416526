import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import app from './modules/app'
import dict from './modules/dict'
import user from './modules/user'
import tab from './modules/tab'
import getters from "./getters";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    dict,
    user,
    tab
  },
  getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});
export default store;
