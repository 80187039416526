import request from "@/utils/request";

// 查询用户列表
export function getUserList(params) {
  return request({
    url: "/admin/users",
    method: "get",
    params: params
  });
}
// 查询在线用户列表
export function getOnlineUserList(params) {
  return request({
    url: "/admin/users/online",
    method: "get",
    params: params
  });
}
//修改禁用状态
export function updateUserDisable(data) {
  return request({
    url: "/admin/users/disable",
    method: "put",
    data: data
  });
}

// 查询用户角色选项
export function getUsersRoleList() {
  return request({
    url: "/admin/users/role",
    method: "get"
  });
}

//修改用户昵称及角色
export function updateUserRole(data) {
  return request({
    url: "/admin/users/role",
    method: "put",
    data: data
  });
}

// 在线用户下限
export function removeOnlineUser(token) {
  return request({
    url: "/admin/users/online/" + token,
    method: "delete"
  });
}

//修改个人信息
export function updateUserInfo(data) {
  return request({
    url: "/admin/users/info",
    method: "put",
    data: data
  });
}

//修改密码
export function updateUserPassword(data) {
  return request({
    url: "/admin/users/password",
    method: "put",
    data: data
  });
}

// 查询用户菜单列表
export function getUserMenusList(params) {
  return request({
    url: "/admin/users/menus",
    method: "get",
    params: params
  });
}
