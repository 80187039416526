const state = {
  tabList: [{ name: "首页", path: "/" }],
}
const mutations = {
  ADD_TAB(state, tab) {
    if (state.tabList.findIndex(item => item.path === tab.path) === -1) {
      state.tabList.push({ name: tab.name, path: tab.path });
    }
  },
  DEL_TAB(state, tab) {
    const index = state.tabList.findIndex(item => item.name === tab.name);
    state.tabList.splice(index, 1);
  },
  RESET_TAB(state, tab) {
    if(tab){
      state.tabList = [{ name: "首页", path: "/" },{name:tab.name,path:tab.path}];
    }else{
      state.tabList = [{ name: "首页", path: "/" }];
    }
  },
  DEL_RIGHT_TABS: (state, tab) => {
    const index = state.tabList.findIndex(v => v.path === tab.path)
    if (index === -1) {
      return
    }
    state.tabList = state.tabList.filter((item, idx) => {
      return idx <= index;
    })
  },
  DEL_LEFT_TABS: (state, tab) => {
    const index = state.tabList.findIndex(v => v.path === tab.path)
    if (index === -1) {
      return
    }
    state.tabList = state.tabList.filter((item, idx) => {
      return idx >= index || item.path === '/';
    })
  },
}
const actions = {
  saveTab({ commit }, tab) {
    commit("ADD_TAB", tab);
  },
  removeTab({ commit }, tab) {
    commit("DEL_TAB", tab);
  },
  resetTab({ commit }, tab) {
    commit("RESET_TAB", tab);
  },
  removeRightTabs({ commit }, tab) {
    commit("DEL_RIGHT_TABS", tab);
  },
  removeLeftTabs({ commit }, tab) {
    commit("DEL_LEFT_TABS", tab);
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
